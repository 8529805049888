<template>
    <div class="questions-container">
        <div class="questions-labels px-1">
            <div class="progress-bar">
                <span>{{ $t('modules.exams.assestment.questions.progressLabel') }}</span>
                <div class="linear-container" style="min-height: 4px;">
                    <v-progress-linear
                        v-model="completedPercentage"
                        active
                        :indeterminate="false"
                        :query="true"
                    ></v-progress-linear>
                </div>
                <span>{{ totalViewedQuestions }} / {{ totalQuestions }}</span>
            </div>
            <div class="answers">
                <span>{{ $t('modules.exams.assestment.questions.answersLabel') }}</span>
            </div>
            <div class="state">
                <span>{{ $t('modules.exams.assestment.questions.stateLabel') }}</span>
            </div>
        </div>
        <div class="questions-data">
            <v-card id="scrollable-questions" outlined class="px-1 py-3">
                <ol style="width: 100%">
                    <li
                        v-for="(question, i) of questions"
                        :key="i"
                        :id="`question-${i}`"
                        :class="[{'actual': actualQuestion === question.id}]"
                        @click="changeQuestion(question.id)">

                        <!-- <div class="questions">
                            <v-img v-if="isImage(question)" :src="imageContentBuilder(question)" class="question-img"></v-img>
                            <span v-else>{{ question.descripcion }}</span>
                        </div> -->
                        <div class="questions">{{ isImage(question) ? $t('modules.exams.assestment.questions.seeImage') : question.descripcion }}</div>
                        <div class="answers">{{ seletedAnswers[i] }}</div>
                        <div class="states"><QuestionCircle :status="{...question.status}" :number="i + 1"></QuestionCircle></div>
                    </li>
                </ol>
            </v-card>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            QuestionCircle: () => import('./QuestionCircle.vue'),
        },
        props: {
            totalQuestions: {type: Number, required: true},
            totalViewedQuestions: {type: Number, default: 0},
            actualQuestion: {type: Number, required: true},
            questions: {type: Array, required: true}
        },
        computed: {
            completedPercentage () {
                return this.totalViewedQuestions / this.totalQuestions * 100;
            },
            seletedAnswers () {
                return this.questions.map((question) => {
                    return question.respuestas
                        .filter((answer) => answer.elegida === 'S')
                        .map((answer) => answer.orden)
                        .join(' - ')
                });
            }
        },
        methods: {
            changeQuestion(id) {
                this.$emit('checkSavedAnswer', () => { this.goToQuestion(id) })
            },
            isImage (question) {
                return !!question?.media?.imagen;
            },
            imageContentBuilder (question) {
                let src = 'data:image/';
                const [extension] = question.media.descripcion.split('.').pop();
                src += `${extension.toLowerCase()};base64,${question.media.imagen}`;
                return src;
            },
            goToQuestion(id) {
                if (id !== this.actualQuestion) {
                    this.questions.forEach((question) => {
                        if (question.id === this.actualQuestion) {
                            question.status.actual = false;
                        } else if (question.id === id) {
                            question.status.actual = true;
                            question.status.viewed = true;
                        }
                    });
                }

                this.$emit('update:questions', [...this.questions]);
                this.$emit('update:actualQuestion', id)
            },
        },
        mounted() {
            if (this.questions && this.questions.length) {
                this.$emit('update:actualQuestion', this.questions[0].id);
                this.questions[0].status.viewed = true;
                this.questions[0].status.actual = true;

                const totalViewed = this.questions.reduce((acc, item) => {
                    if (item.status.viewed) return acc += 1;
                    return acc;
                }, 0);

                this.$emit('update:totalViewedQuestions', totalViewed)
            }
        }
    }
</script>

<style lang="sass" scoped>
.questions-container
  display: flex
  flex-direction: column

  .questions-labels,
  .questions-data
    display: flex
    flex-direction: row
    margin-right: .3rem

  .questions-labels
    align-items: center
    min-height: 48px
    padding-bottom: .5rem

    .progress-bar
      display: flex
      flex-direction: row
      align-items: center
      justify-content: flex-start
      padding: 0 1rem
      width: 80%

      span:first-child
        font-size: 14px
        font-weight: bold
        color: rgba(0, 0, 0, 0.87)
        margin-right: 1rem

      .linear-container
        width: 10rem
        margin-right: 1rem

      span:last-child
        font-size: 12px
        color: var(--v-primary-base)

    .answers,
    .state
      display: flex
      justify-content: center
      width: 10%
      font-size: 14px
      font-weight: bold
      color: rgba(0, 0, 0, 0.87)

  .questions-data
    .v-card
      display: flex
      flex-direction: row
      width: 100%
      background-color: rgba(77, 148, 255, 0.08)
      min-height: 180px
      max-height: 180px
      background-color: rgba(77, 148, 255, 0.08)
      overflow-y: scroll

      ol
        list-style: none
        counter-reset: custom-counter

        li
          display: flex
          flex-direction: row
          align-items: center
          font-size: 12px
          color: rgba(0, 0, 0, 0.7)
          width: 100%
          counter-increment: custom-counter
          cursor: pointer

          &.actual
            font-weight: bold
            color: var(--v-primary-base)

          &::before
            font-family: 'Material Design Icons'
            font-size: 24px
            content: '\F142'
            color: var(--v-primary-base)
            margin-right: 1rem
            visibility: hidden

          &.actual::before
            visibility: visible

          .questions
            width: 80%

            .question-img
              width: fit-content
              display: inline-block
            &::before
              content: counter(custom-counter) ". "
              font-weight: bold
              margin-right: .7rem

          .answers,
          .states
            display: flex
            flex-direction: row
            justify-content: center
            width: 10%
</style>
